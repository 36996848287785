
function Footer() {
    
    return (
        <>
 <section class="w3l-call-to-action-6 py-4">
        <div class="container py-md-5 py-sm-4 py-4">
            <div class="d-lg-flex align-items-center ">
                <div class="col-sm-5   ">
                    <h3 class="title-big">Quality Service with
                        Free Collection & Delivery...</h3>
                </div>
                <div class="col-sm-2"></div>
                <div class="col-sm-4 ">
                    <ul class="buttons d-sm-flex align-items-center ">
                    <li class="phone-sec me-xl-4 me-lg-0 me-sm-4">
                            <a class="call-style-w3" href="tel:+966532648650">+966532648650</a>
                        </li>
                        <li class="phone-sec me-xl-4 me-lg-0 me-sm-4">
                            <a class="call-style-w3" href="tel:+966555873301">+966555873301</a>
                        </li>
                        <div class="col-sm-4">
                      
                        </div>
                    </ul>
                </div>
            </div>
        </div>
    </section>
<footer class="">
<div class="footer-29-w3l">
    <div class="container  pt-4">
        <div class="w3l-footer-text-style">
         
        </div>
        <div class="row footer-top-29 pt-sm-4">
            <div class="col-lg-3 col-sm-6">
                <div class="address-grid">
                <h5 class="top-bold">Link</h5><br></br>
      

                        <li><a href="/"><font color="#fff">Home</font></a></li>
                        <li><a href="/About"><font color="#fff">About Us</font></a></li>
                        <li><a href="/Servies"><font color="#fff">Services</font></a></li>
                        <li><a href="/Contact"><font color="#fff">Contact Us</font></a></li>
                
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 mt-sm-0 mt-4">
                <div class="address-grid">
                    <h5 class="top-bold">Support</h5>
                    <br></br>
                    {/* <h5 class="phone-number-text mt-2"><a href="tel:+1(21) 112 7368">+1(21) 112 7368</a></h5> */}
                    <ul>

                    <li><a href="#privacy"><font color="#fff">Privacy Policy</font></a></li>
                        <li><a href="#terms"> <font color="#fff">Terms of Service</font></a></li>
                        <li><a href="/Contact"><font color="#fff">Contact us</font></a></li>
                        <li><a href="#support"><font color="#fff"> Support</font></a></li>
                        </ul>
                </div>
                
            </div>
            <div class="col-lg-3 col-md-6 col-sm-5 footer-list-menu ps-lg-0 mt-lg-0 mt-sm-5 mt-4">
                <div class="address-grid">
                    <h5 class="mb-sm-4 mb-3 pb-lg-2 top-bold">Address</h5>
                    <ul>
                        <li><a href="#privacy"><font color="#fff">Air Frost Trading EST
 Dammam
 Muhammadiya Saudi Arabia</font></a></li>
                       
                    </ul>
                </div>
            </div>
            <div class="address-grid col-lg-3 col-md-6 col-sm-7 mt-lg-0 mt-sm-5 mt-4 w3l-footer-16-main">
                <h5 class="top-bold">Phone</h5>
              
                <font color="#fff">General manager : Fadil</font><h5 class="phone-number-text mt-2"><a href="tel:+966555873301"><font color="#fff">+966555873301</font></a></h5>
<br></br>
                <font color="#fff"> Technical manager : Joby</font><h5 class="phone-number-text mt-2"><a href="tel: +966532648650"> <font color="#fff">+966532648650</font></a></h5>
                <div class="address-grid mt-sm-5 mt-4">
                    <h5 class="top-bold"></h5>
                    {/* <h5 class="email-cont-text mt-1"> <a href="mailto:photogenic@mail.com"
                            class="mail">xyzz@mail.com</a></h5> */}
                             
                </div></div>
        </div>
      
        <div class="w3l-copyright text-center mt-lg-5 mt-4 pt-sm-4 pt-3">
        <div class="main-social-footer-29">
                    <a href="#facebook" class="facebook"><i class="fab fa-facebook-f"></i></a>
                    <a href="#twitter" class="twitter"><i class="fab fa-twitter"></i></a>
                    <a href="#instagram" class="instagram"><i class="fab fa-instagram"></i></a>
                    <a href="#linkedin" class="linkedin"><i class="fab fa-linkedin-in"></i></a>
                </div>
            <p class="copy-footer-29">© 2023 Airfrost. All rights reserved Design by  <a
                    href="http://www.infosiltechnolabs.com/" target="_blank">
                    <img src="assets/images/ilogo.png" class="infosil" alt=""/></a></p>
                 
        </div>
    </div>
</div>
</footer>
</>

);

}

export default Footer;