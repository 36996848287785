
import Footer from '../Pages/Footer';
function Servies() {
    
    return (
        <>
    
    <div class="snowflakes" aria-hidden="true">
 
 <div class="snowflake">
 <font color='#1e96fc'>❅</font>
 </div>
 <div class="snowflake">
 <font color='#1e96fc'>❅</font>
 </div>
 <div class="snowflake">
 <font color='#1e96fc'>❅</font>
 </div>
 <div class="snowflake">
 <font color='#1e96fc'>❅</font>
 </div>
 <div class="snowflake">
 <font color='#1e96fc'>❅</font>
 </div>
 <div class="snowflake">
 <font color='#1e96fc'>❅</font>
 </div>
 <div class="snowflake">
 <font color='#1e96fc'>❅</font>
 </div>
 <div class="snowflake">
 <font color='#1e96fc'>❅</font>
 </div>
 <div class="snowflake">
 <font color='#1e96fc'>❅</font>
 </div>
 <div class="snowflake">
 <font color='#1e96fc'>❅</font>
 </div>
</div>
<br></br>
 {/* <section class="inner-banner1  py-5">
        <div class="w3l-breadcrumb py-lg-5">
            <div class="container pt-5 pb-sm-4 pb-2">
            <div class="container pt-5 pb-sm-4 pb-2">
            <h3 class="title-style text-aly"><font color="#fff"></font> </h3>
             </div>  
            </div>
        </div>
    </section> */}
    <div id="carouselExampleSlidesOnly" class="carousel slide" data-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img class="d-block w-100 conimg" src="assets/images/hom.jpg" alt=""/>
      <div class="carousel-caption">
    <h5 class="hed"><font color="#fff">Keeping You Cool In Every Step...</font></h5>
  
    </div>
   </div>
  </div>
</div>
    <div class="w3l-grids-block-5 py-5 ">
        <div class="container py-lg-5 py-md-4 py-2">
            <div class="title-main text-center mx-auto mb-md-5 mb-4" >
                <h5 class="sub-title">What We Offer</h5>
                <h3 class="title-style">24-Hour<font color="#4895EF"> Service And Maintenance</font></h3>
            </div>
            <div class="row text-center justify-content-center">
                <div class="col-lg-4 col-md-6">
                    <div class="servicecard-single">
                        <div class="grids5-info position-relative">
                            <img src="assets/images/ac3.jpg" alt="" class="img-fluid" />
                        </div>
                        <div class="content-main-top">
                            <h4><a >Air Conditioning Services</a></h4><br></br>
                            <p class="text">When it comes to keeping your home or business comfortable year-round, a reliable air conditioning system is essential. At Air Frost, we are your trusted partner for a wide range of air conditioning services. Our team of experienced technicians is dedicated to providing top-notch solutions to meet all your cooling needs.
                            </p><br></br><br></br>
                            {/* <a class="btn btn-style mt-4" href="services.html">Read More</a> */}
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mt-md-0 mt-4">
                    <div class="servicecard-single">
                        <div class="grids5-info position-relative">
                            <img src="assets/images/ac.jpg" alt="" class="img-fluid" />
                        </div>
                        <div class="content-main-top">
                            <h4><a >Installation</a></h4><br></br>
                            <p class="text">Are you in need of a new air conditioning system? Our expert technicians specialize in the seamless installation of various AC units. Whether you're looking to install a central air system, ductless mini-split, or any other type of cooling system, we've got you covered. We'll ensure your new AC unit is installed correctly to optimize efficiency and performance.
                            </p>
                            {/* <a class="btn btn-style " href="services.html">Read More</a> */}
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mt-lg-0 mt-4">
                    <div class="servicecard-single">
                        <div class="grids5-info position-relative">
                            <img src="assets/images/ac2.jpg" alt="" class="img-fluid" />
                        </div>
                        <div class="content-main-top">
                            <h4><a >Maintenance</a></h4><br></br>
                            <p class="text">Regular maintenance is key to extending the lifespan of your air conditioning system and ensuring it operates at peak efficiency. Our maintenance services include thorough inspections, filter cleaning or replacement, refrigerant checks, and more. Schedule routine maintenance to prevent breakdowns and keep your energy bills in check.
                            </p><br></br>
                            {/* <a class="btn btn-style mt-4" href="services.html">Read More</a> */}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row text-center justify-content-center">
                <div class="col-lg-4 col-md-6">
                    <div class="">
                        <div class="grids5-info position-relative">
                            <img src="assets/images/hund.jpg" alt="" class="img-fluid" />
                        </div>
                        <div class="content-main-top">
                            <h4><a >Air Quality Services</a></h4><br></br>
                            <p class="text">we are dedicated to providing top-notch air quality services that complement your air conditioning system. Indoor air quality plays a crucial role in maintaining a healthy and comfortable living or working environment. When it comes to your air conditioning system, ensuring optimal air quality is essential not only for your comfort but also for your overall well-being.     </p><br/><br/>
                            {/* <a class="btn btn-style mt-4" href="services.html">Read More</a> */}
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mt-md-0 mt-4">
                    <div class="">
                        <div class="grids5-info position-relative">
                            <img src="assets/images/truck.jpg" alt="" class="img-fluid" />
                        </div>
                        <div class="content-main-top">
                            <h4><a href="services.html">Free Collection & Delivery</a></h4><br></br>
                            <p class="text">Are you in need of a new air conditioning system? Our expert technicians specialize in the seamless installation of various AC units. Whether you're looking to install a central air system, ductless mini-split, or any other type of cooling system, we've got you covered. We'll ensure your new AC unit is installed correctly to optimize efficiency and performance.
                            </p><br></br>
                            {/* <a class="btn btn-style " href="services.html">Read More</a> */}
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mt-lg-0 mt-4">
                    <div class="">
                        <div class="grids5-info position-relative">
                            <img src="assets/images/abcd.jpg" alt="" class="img-fluid" />
                        </div>
                        <div class="content-main-top">
                            <h4><a href="services.html">Duct Cleaning</a></h4><br></br>
                            <p class="text">we specialize in professional duct cleaning services that can make a significant difference in your indoor air quality and comfort. When it comes to maintaining a healthy and efficient air conditioning system, one often-overlooked aspect is the cleanliness of your ductwork.</p><br/>
                            {/* <a class="btn btn-style mt-4" href="services.html">Read More</a> */}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row text-center justify-content-center">
                <div class="col-lg-4 col-md-6">
                    <div class="servicecard-single">
                        <div class="grids5-info position-relative">
                            <img src="assets/images/s1.png" alt="" class="img-fluid" />
                        </div>
                        <div class="content-main-top">
                            <h4><a >Split AC Services</a></h4>
                            <p class="text">Whether you're upgrading to a new split AC system or installing one for the first time, our expert technicians are here to help. We ensure the precise installation of your split AC unit, optimizing its performance and efficiency. Our goal is to provide you with a cooling solution that meets your needs and budget.
                            </p><br/><br/>
                            {/* <a class="btn btn-style mt-4" href="services.html">Read More</a> */}
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mt-md-0 mt-4">
                    <div class="servicecard-single">
                        <div class="grids5-info position-relative">
                            <img src="assets/images/s3.png" alt="" class="img-fluid" />
                        </div>
                        <div class="content-main-top">
                            <h4><a>Ductable AC Services</a></h4>
                            <p class="text">Is your ductable AC system experiencing issues such as uneven cooling, reduced airflow, or unusual noises? Our team specializes in diagnosing and repairing ductable AC problems promptly and effectively. We understand the importance of maintaining a comfortable and healthy indoor environment, and we're here to restore your system to its peak performance.
                            </p>
                            {/* <a class="btn btn-style mt-4" href="services.html">Read More</a> */}
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mt-lg-0 mt-4">
                    <div class="servicecard-single">
                        <div class="grids5-info position-relative">
                            <img src="assets/images/s2.png" alt="" class="img-fluid" />
                        </div>
                        <div class="content-main-top">
                            <h4><a>Cassette AC Services</a></h4>
                            <p class="text">Ensure optimal cooling in commercial spaces with our expert cassette AC installation services. We carefully assess your space's layout and requirements to design and install systems that provide superior performance and energy efficiency.
                            </p><br/><br/><br/><br/>

                            {/* <a class="btn btn-style mt-4" href="services.html">Read More</a> */}
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mt-4">
                    <div class="servicecard-single">
                        <div class="grids5-info position-relative">
                            <img src="assets/images/s4.png" alt="" class="img-fluid" />
                        </div>
                        <div class="content-main-top">
                            <h4><a>Ventilation</a></h4>
                            <p class="text">Is your existing ventilation system not performing optimally? Our experienced technicians are well-equipped to diagnose and repair any ventilation system issues promptly. We'll restore proper air circulation and filtration for a healthier indoor environment.
                            </p><br/>
                            {/* <a class="btn btn-style mt-4" href="services.html">Read More</a> */}
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mt-4">
                    <div class="servicecard-single">
                        <div class="grids5-info position-relative">
                            <img src="assets/images/s5.png" alt="" class="img-fluid" />
                        </div>
                        <div class="content-main-top">
                            <h4><a>Variable Refrigerant Volume</a></h4>
                            <p class="text">Take advantage of VRV's zoning capabilities to achieve precise temperature control in different areas of your home or building. We can help you design a zoning plan that suits your specific needs, allowing occupants to enjoy personalized comfort while conserving energy.
                            </p>
                            {/* <a class="btn btn-style mt-4" href="services.html">Read More</a> */}
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mt-4">
                    <div class="servicecard-single">
                        <div class="grids5-info position-relative">
                            <img src="assets/images/s6.png" alt="" class="img-fluid" />
                        </div>
                        <div class="content-main-top">
                            <h4><a>Air Cleaners</a></h4>
                            <p class="text">For targeted air purification, we offer a range of portable air purifiers that can be placed in specific rooms or areas of your home. These units are highly effective at removing airborne contaminants and improving the air you breathe.
                            </p><br/><br/>
                            {/* <a class="btn btn-style mt-4" href="services.html">Read More</a> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div><br></br>
    <div class="toping"></div>
<div class="firstbg">
<section class="w3l-bottom-grids-6 py-5">
<div class="container pt-lg-5 pt-md-4 pt-2">
    <center><h2><font color="white">We provide our customers with...</font></h2></center>
    <br></br><br></br><br></br>
    <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6">
            <div class="area-box3">
            {/* <i class="fa-solid fa-heart"></i> */}
                <h4><a  class="title-head">Complete Customer Satisfaction</a></h4>
                <p class="text">We provide customer's satisfaction rate of 100% we strive to keep our work to the best quality possible we try to build customer relations to the fullest and help then in finding the best service they need.</p>
            </div>
        </div>
        <div class="col-lg-4 col-md-6 mt-md-0 mt-4">
            <div class="area-box3">
            {/* <i class="fa-solid fa-earth-americas"></i> */}
                <h4><a  class="title-head">Environment-Friendly Practices</a></h4>
                <p class="text">we are providing environmentally responsible AC solutions. Our range of environment-friendly practices in air conditioning devices not only helps reduce carbon emissions but also ensures efficient cooling for our customers.<br></br><br></br></p>
            </div>
        </div>
        <div class="col-lg-4 col-md-6 mt-lg-0 mt-4">
            <div class="area-box3">
                {/* <i class="fas fa-thumbs-up"></i> */}
                <h4><a  class="title-head">24/7 Assistance In Case Of Breakdown</a></h4>
                <p class="text">We are available 24/7 and even on national holidays we are ready to serve our customers with just a phone call.<br></br><br></br><br></br></p>
            </div>
        </div>
    </div><br></br>
    <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6">
            <div class="area-box">
            {/* <i class="fa-solid fa-chart-simple"></i> */}
                <h4><a href="" class="title-head">Consultation and Design</a></h4>
                <p class="text">we offer expert consultation and custom design services tailored to your specific needs. When it comes to ensuring optimal comfort and energy efficiency in your indoor spaces, the consultation and design phase of your air conditioning system is paramount.</p>
            </div>
        </div>
        <div class="col-lg-4 col-md-6 mt-md-0 mt-4">
            <div class="area-box">
            {/* <i class="fa-solid fa-gears"></i> */}
                <h4><a  class="title-head">Comprehensive system inspections</a></h4>
                <p class="text">We offer comprehensive system inspection services that go beyond a cursory check. Regular maintenance and inspections are the keys to ensuring your air conditioning system operates efficiently, reliably, and extends its lifespan.</p>
            </div>
        </div>
        <div class="col-lg-4 col-md-6 mt-lg-0 mt-4">
            <div class="area-box">
            {/* <i class="fa-solid fa-industry"></i> */}
                <h4><a  class="title-head">Energy-efficient system upgrades</a></h4>
                <p class="text">we specialize in energy-efficient system upgrades for your air conditioning system. In today's world, energy efficiency is not just a buzzword; it's a crucial consideration for both cost-conscious homeowners and environmentally conscious individuals.</p>
            </div>
        </div>
    </div>
</div>
</section></div>

    <section class="">
        <div class="container py-lg-5 py-md-4 py-2">
            <div class="row justify-content-between align-items-center pb-lg-5">
                <div class="col-lg-6 about-2-secs-right mb-lg-0 mb-5">

                <img src="assets/images/pfl.jpg" alt="card-image" class="img-fluid"/>

                   </div>
                <div class="col-lg-6 about-2-secs-left ps-lg-5 mt-lg-4 mt-5">
                    
                    <h3 class="title-style"><font color="#4895EF">Air Conditioning</font> Experts...</h3>
                    <p class="mt-4 text">We all love living in comfort, especially when the temperatures around us start to soar high. And living in a tropical nation means dealing with varied weather conditions. Depending on where you live in the country, the heat can at times get unbearable, much like the cold. And when it comes to heat, if the weather is too dry or too humid, it can leave you feeling more fatigued than you are. That’s when air conditioners come in handy. Even though it’s artificial cooling, they provide a much needed sigh of relief in the heat and ensure you feel calm and comfortable</p>
                    {/* <ul class="mt-4 list-style-lis">
                        <li><i class="fas fa-check-circle"></i>100% Customer Satisfaction</li>
                        <li><i class="fas fa-check-circle"></i>Free Collection & Delivery</li>
                        <li><i class="fas fa-check-circle"></i>Affordable Prices</li>
                        <li><i class="fas fa-check-circle"></i>Free Collection & Delivery</li>
                        <li><i class="fas fa-check-circle"></i>Affordable Prices</li>
                    </ul> */}
                    {/* <a href="about.html" class="btn btn-style mt-5">Discover More</a> */}
                </div>
            </div>
        </div>
    </section>
    <Footer></Footer>
</>

);

}

export default Servies;