import Home from './Pages/Home';
import About from './Pages/About';
import Footer from './Pages/Footer';
import Servies from './Pages/Servies';
import Contact from './Pages/Contact';
import { useState } from 'react';
import './App.css';

import ThemeSwitcher from './ThemeSwitcher';
import {BrowserRouter as Router,Link,NavLink,Route,Routes} from "react-router-dom";
function App() {
    const [logoVisible, setLogoVisible] = useState(true);
    const toggleLogoVisibility = () => {
        setLogoVisible(!logoVisible);
      };
    
    return (
    <Router>
   <>

<div class="topnav">
<div class="container">
  <div class="row">
    <div class="col-sm-10">                            
<h4 class="toptext">Book Now: <a class="phtop" href="tel:+966532648650"> +966532648650 </a>, <a class="phtop" href="tel:+966555873301">+966555873301</a> </h4>
    </div>
    <div class="col-sm-2">
      <div class="topcolor">
    <i class="fa-brands fa-facebook"></i> &nbsp; <i class="fa-brands fa-whatsapp"></i> &nbsp;<i class="fa-brands fa-instagram"></i> &nbsp; 
    </div>
    </div>
  </div>
</div>












</div>
    <header id="site-header" class="">
        <div class="container">
            <nav class="navbar navbar-expand-lg navbar-light">
                <a class="navbar-brand" href="/">
              
              <img src="assets/images/logo.png" alt="" className="logo" /> 
              <font size="5" >Airfrost</font><font size='4'> Trading</font>
            
                </a>
                <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon fa icon-expand fa-bars"></span>
                  <a href='' > <span class="navbar-toggler-icon fa icon-close fa-times"></span></a>
                </button>
                <div class="collapse navbar-collapse" id="navbarScroll">
                    <ul class="navbar-nav ms-auto my-2 my-lg-0 navbar-nav-scroll">
                        <li class="nav-item">
                         <a class="nav-link sizeing " aria-current="page" href="/" >Home</a>
                        </li>
                        <li class="nav-item">
                        <a class="nav-link sizeing" href="/About">About</a>
                        </li>
                     
                        <li class="nav-item ">
                        <div class="dropdown ">
  <a class="nav-link sizeing " href='/Servies'>Services</a>
  <div class="dropdown-content">
    <a href="/Servies" class="nav-linky" >Installation</a>
    <a href="/Servies" class="nav-linky">Air Quality</a>
    <a href="/Servies" class="nav-linky" >Ductable AC</a>
  </div>
</div>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link sizeing" href="/Contact">Contact</a>
                        </li>

                        {/* <ThemeSwitcher onThemeSwitch={toggleLogoVisibility} /> */}




   
                    </ul>
                    {/* <form action="#error" method="GET" class="d-flex search-header ms-lg-2">
                        <input class="form-control" type="search" placeholder="Enter Keyword..." aria-label="Search"
                            required/>
                        <button class="btn btn-style" type="submit"><i class="fas fa-search"></i></button>
                    </form> */}
                </div>
            
              
                
            </nav>
         
        </div>
    </header>
    <Routes><Route exact path={"/"} element={<Home></Home>}></Route>  
    <Route path={"/About"} element={<About></About>}></Route>
   <Route path={"/Footer"} element={<Footer></Footer>}></Route>
   <Route path={"/Servies"} element={<Servies></Servies>}></Route>
   <Route path={"/Contact"} element={<Contact></Contact>}></Route>
  
   </Routes> 
   </>
</Router>
   
   
   
   
   
   

  );
}

export default App;
