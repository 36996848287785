
import Footer from '../Pages/Footer';
import React, { useEffect } from 'react';
function About() {
    const showVideoPopup = () => {
        document.getElementById("videoPopup").style.display = "flex";
      }
    
      const hideVideoPopup = () => {
        document.getElementById("videoPopup").style.display = "none";
      }
    useEffect(() => {
        const showVideoPopup = () => {
          document.getElementById("videoPopup").style.display = "flex";
        }
    
        const hideVideoPopup = () => {
          document.getElementById("videoPopup").style.display = "none";
        }
    
        // Clean-up function (componentWillUnmount)
        return () => {
          // Clean up any event listeners or subscriptions here
        };
      }, []);
    return (
        <>
   
   <div class="snowflakes" aria-hidden="true">
 
 <div class="snowflake">
 <font color='#1e96fc'>❅</font>
 </div>
 <div class="snowflake">
 <font color='#1e96fc'>❅</font>
 </div>
 <div class="snowflake">
 <font color='#1e96fc'>❅</font>
 </div>
 <div class="snowflake">
 <font color='#1e96fc'>❅</font>
 </div>
 <div class="snowflake">
 <font color='#1e96fc'>❅</font>
 </div>
 <div class="snowflake">
 <font color='#1e96fc'>❅</font>
 </div>
 <div class="snowflake">
 <font color='#1e96fc'>❅</font>
 </div>
 <div class="snowflake">
 <font color='#1e96fc'>❅</font>
 </div>
 <div class="snowflake">
 <font color='#1e96fc'>❅</font>
 </div>
 <div class="snowflake">
 <font color='#1e96fc'>❅</font>
 </div>
</div>
<br></br>
 {/* <section class="inner-banner4 py-5">
        <div class="w3l-breadcrumb py-lg-5">
            <div class="container pt-5 pb-sm-4 pb-2">
            <h3 class="title-style text-al"><font color="#fff">A Breath Of Fresh Air</font> </h3>
                    <p class="mt-4 text-al"><font color="#fff">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis</font></p>
               
            </div>
        </div>
    </section> */}








<div id="carouselExampleSlidesOnly" class="carousel slide" data-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img class="d-block w-100 conimg" src="assets/images/home.jpg" alt=""/>
      <div class="carousel-caption">
    <h5 class="hed"><font color="#fff">A Breath Of Fresh Air</font></h5>
   
    </div>
   </div>
  </div>
</div>










    <section class="w3l-about-2 py-5">
        <div class="container py-lg-5 py-md-4 py-2">
            <div class="row justify-content-between align-items-center pb-lg-5">
                <div class="col-lg-6 about-2-secs-right mb-lg-0 mb-5">
                    <div class="image-box inverse position-relative">
                        <div class="image-box__static">
                            <img src="assets/images/ab2.jpg" alt="" width="454" height="459"/>
                        </div>
                        <div class="image-box__float">
                            {/* <img src="assets/images/o2.jpg" alt="" width="364" height="459"/> */}
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 about-2-secs-left ps-lg-5 mt-lg-4 mt-5">
                    
                    <h3 class="title-style">Why <font color="#4895EF"> Airfrost?</font></h3>
                    <p class="mt-4 text">Airfrost Air-conditioning  has proven to be one of the leading Air-conditioning Contractors in the region. Our edifice being built on utmost customer satisfaction, competent performance and reliability has gained us good Reputation within a short time. Airfrost undertakes various contracts for the design, supply and installation of air-conditioning systems for all the commercial, industrial, residential buildings and villas. Airfrost makes us unique and always the first choice among our clients. Airfrost is proud to have a well-equipped and efficient maintenance team, who are equipped to trouble shoot and preserve any kind of air-conditioning devices. Airfrost offers 24/7 assistance for any breakdown or cessation so that our clients shall always stay cool. </p>
                    {/* <ul class="mt-4 list-style-lis">
                        <li><i class="fas fa-check-circle"></i>100% Customer Satisfaction</li>
                        <li><i class="fas fa-check-circle"></i>Free Collection & Delivery</li>
                        <li><i class="fas fa-check-circle"></i>Affordable Prices</li>
                    </ul> */}
                    {/* <a href="about.html" class="btn btn-style mt-5">Discover More</a> */}
                </div>
            </div>
        </div>
    </section>



    <section class="">
        <div class="container ">
           <center> <h2><b>Our Work</b> </h2>
           <hr></hr></center>
            <div class="row justify-content-between ">
                <div class="col-lg-6 about-2-secs-right ">
                <div>
      <div className="containery" >
        <img src="assets/images/ab5.jpeg" alt="" width="464" height="459"/>
        {/* <div className="play-button">▶️</div> */}
      </div>

      {/* <div className="video-popup" id="videoPopup" onClick={hideVideoPopup}>
        <iframe title="video" src="assets/images/v1.mp4" frameBorder="0" allowFullScreen></iframe>
      </div> */}
    </div>
                </div>
                <div class="col-lg-6 about-2-secs-left ps-lg-5 mt-lg-4 mt-5">
                    
                    <h3 class="">-18 c Application Cold Store </h3>
                    <p class="mt-4 text">  </p>
                
                    Step into the frozen embrace of our -18°C cold store space, where frosty temperatures meet precision-controlled environments. Ideal for storing perishable goods, pharmaceuticals, or delicate materials, our state-of-the-art facility ensures optimal preservation and quality retention. With advanced technology and meticulous attention to maintaining sub-zero conditions, trust us to safeguard your valuable inventory with unwavering reliability. Explore the chill of efficiency and excellence with our -18°C cold store space
                </div>
            </div>


<br></br><br></br>
<div>
    



            <div class="row justify-content-between ">
            <div class="col-lg-6 about-2-secs-left ps-lg-5 mt-lg-4 mt-5">
                    
                    <h3 class="">+3 c Application Chill Room </h3>
                    <p class="mt-4 text"> Explore our Application Chill Room, the ultimate space for unwinding and recharging. Dive into a serene atmosphere designed to help you take a breather from your busy schedule. Whether you're seeking solitude or want to connect with fellow enthusiasts, our chill room offers a cozy retreat. Engage in leisure activities, grab some snacks, and let your creativity flow in this tranquil haven. </p>
                
                    {/* <a href="about.html" class="btn btn-style mt-5">Discover More</a> */}
                </div>
                <div class="col-lg-6 about-2-secs-right ">
                <div className="containery" >
        <img src="assets/images/ab4.jpeg" alt="" width="464" height="459"/>

      </div>

      {/* <div className="video-popup" id="videoPopup" onClick={hideVideoPopup}>
        <iframe title="video" src="assets/images/v1.mp4" frameBorder="0" allowFullScreen></iframe>
      </div> */}
    </div>
                </div>
               
            </div>



         <br></br>

            <div class="row justify-content-between ">
                <div class="col-lg-6 about-2-secs-right ">
                <div>
      <div className="containery" >
        <img src="assets/images/ab3.jpg" alt="" width="464" height="459"/>
        {/* <div className="play-button">▶️</div> */}
      </div>

      {/* <div className="video-popup" id="videoPopup" onClick={hideVideoPopup}>
        <iframe title="video" src="assets/images/v1.mp4" frameBorder="0" allowFullScreen></iframe>
      </div> */}
    </div>
                </div>
                <div class="col-lg-6 about-2-secs-left ps-lg-5 mt-lg-4 mt-5">
                    
                    <h3 class="">Package Air Conditioner </h3>
                    <p class="mt-4 text"> Introducing our Package Air Conditioner, the ultimate solution for your cooling needs. Designed to deliver powerful and efficient climate control, our system seamlessly integrates into your space with minimal disruption. Whether for residential, commercial, or industrial applications, our Package Air Conditioner offers customizable settings to ensure comfort in any environment. With advanced features such as energy-saving modes and intelligent temperature regulation, you can enjoy optimal cooling while minimizing energy consumption. Experience reliability, performance, and convenience with our Package Air Conditioner, your partner in creating the perfect indoor climate. </p>
                
                    
                </div>
            </div>


<br></br><br></br>
<div>
    



            <div class="row justify-content-between ">
            <div class="col-lg-6 about-2-secs-left ps-lg-5 mt-lg-4 mt-5">
                    
                    <h3 class="">Slpit AC </h3>
                    <p class="mt-4 text">Discover unparalleled comfort and efficiency with our Split Air Conditioning system. Engineered to provide whisper-quiet operation and powerful cooling performance, our Split AC seamlessly blends into any space, whether it's a cozy home, bustling office, or dynamic retail environment. With innovative inverter technology, our system adapts to your cooling needs in real-time, maximizing energy efficiency and reducing utility costs. Say goodbye to hot spots and uneven cooling—our Split AC distributes refreshing air evenly throughout the room, ensuring consistent comfort year-round. Enjoy the perfect balance of performance, reliability, and style with our Split Air Conditioning solution, designed to elevate your indoor environment to new heights of comfort.</p>
                
                    {/* <a href="about.html" class="btn btn-style mt-5">Discover More</a> */}
                </div>
                <div class="col-lg-6 about-2-secs-right ">
                <div className="containery" >
        <img src="assets/images/o2.jpg" alt="" width="464" height="459"/>

      </div>

      {/* <div className="video-popup" id="videoPopup" onClick={hideVideoPopup}>
        <iframe title="video" src="assets/images/v1.mp4" frameBorder="0" allowFullScreen></iframe>
      </div> */}
    </div>
                </div>
               
            </div>



         <br></br>







        </div>
    </section>
    <br></br> <br></br>
<div class="cotainer">
<center> <h2><b>Our Team</b></h2>
           <hr></hr></center>
<div class="container">

           <div class="row text-center justify-content-center">
                <div class="col-lg-4 col-md-6">
                    <div class="">
                        <div class="grids5-info position-relative">
                            <img src="./assets/images/wq1.jpg" alt="" class="img-fluid" />
                        </div>
                        <div class="content-main-top">
                        {/* <h4><a >.............</a></h4> */}
                           <br/><br/>
                            {/* <a class="btn btn-style mt-4" href="services.html">Read More</a> */}
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mt-md-0 mt-4">
                    <div class="">
                        <div class="grids5-info position-relative">
                            <img src="assets/images/wq2.jpg" alt="" class="img-fluid" />
                        </div>
                        <div class="content-main-top">
                        {/* <h4><a >..........</a></h4> */}
                           <br></br>
                            {/* <a class="btn btn-style " href="services.html">Read More</a> */}
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mt-lg-0 mt-4">
                    <div class="">
                        <div class="grids5-info position-relative">
                            <img src="assets/images/wq3.jpg" alt="" class="img-fluid" />
                        </div>
                        <div class="content-main-top">
                            {/* <h4><a >.......</a></h4> */}
                            <br></br>
                           <br/>
                            {/* <a class="btn btn-style mt-4" href="services.html">Read More</a> */}
                        </div>
                    </div>
                </div>
            </div>
</div>


           
</div>







<div class="asimg"><br></br><br></br><br></br>
    <div class="w3-stats pb-5">
        <div class="container pb-lg-5 pb-md-4 pb-2">
            <div class="row text-center">
                <div class="col-md-3 col-6">
                    <div class="">
                        <div class="timer count-title count-number" data-to="1200" data-speed="1500"></div>
                        <p class="count-text">Projects Done</p>
                    </div>
                </div>
                <div class="col-md-3 col-6">
                    <div class="">
                        <div class="timer count-title count-number" data-to="800" data-speed="1500"></div>
                        <p class="count-text">Client</p>
                    </div>
                </div>
                <div class="col-md-3 col-6 mt-md-0 mt-4">
                    <div class="">
                        <div class="timer count-title count-number" data-to="246" data-speed="1500"></div>
                        <p class="count-text">Staffs</p>
                    </div>
                </div>
                <div class="col-md-3 col-6 mt-md-0 mt-4">
                    <div class="">
                        <div class="timer count-title count-number" data-to="25" data-speed="1500"></div>
                        <p class="count-text">Years</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div><br></br><br></br><br></br>

    <center> <h2><b>Our Work</b></h2>
           <hr></hr></center>

           <div class="container">

<div class="row text-center justify-content-center">
     <div class="col-lg-4 col-md-6">
         <div class="">
             <div class="grids5-info position-relative">
                 <img src="./assets/images/jk1.jpg" alt="" class="img-fluid" />
             </div>
             <div class="content-main-top">
             {/* <h4><a >.............</a></h4> */}
                <br/><br/>
                 {/* <a class="btn btn-style mt-4" href="services.html">Read More</a> */}
             </div>
         </div>
     </div>
     <div class="col-lg-4 col-md-6 mt-md-0 mt-4">
         <div class="">
             <div class="grids5-info position-relative">
                 <img src="assets/images/jk2.jpg" alt="" class="img-fluid" />
             </div>
             <div class="content-main-top">
             {/* <h4><a >..........</a></h4> */}
                <br></br>
                 {/* <a class="btn btn-style " href="services.html">Read More</a> */}
             </div>
         </div>
     </div>
     <div class="col-lg-4 col-md-6 mt-lg-0 mt-4">
         <div class="">
             <div class="grids5-info position-relative">
                 <img src="assets/images/jk3.jpg" alt="" class="img-fluid" />
             </div>
             <div class="content-main-top">
                 {/* <h4><a >.......</a></h4> */}
                 <br></br>
                <br/>
                 {/* <a class="btn btn-style mt-4" href="services.html">Read More</a> */}
             </div>
         </div>
     </div>
 </div>
</div>













   <div class="naimg">
    <section class="home-block-3 py-xl-4 py-2 ">

        <div class="row m-0 align-items-center ">
      
            <div class="col-lg-6 left-w3l-img p-0">
              
            </div>
            <div class="col-lg-6 right-w3l-img mt-lg-0 mt-sm-4 mt-5">
                <div class="p-xl-5 p-sm-4 mx-xl-3">
             
                    <h3 class="title-style">Book Our <font color="#4895EF">Services</font> </h3>
                    <p class="sub-title mt-3"><font color="#fff">After the consultation, we'll provide you with a detailed service proposal that includes a clear breakdown of the scope of work, timeline, and cost. We believe in transparency, and there are no hidden fees or surprises. You can review the proposal and, once you're satisfied, confirm the service with us.</font></p>
                        <p class="sub-title mt-3 text"><font color="#fff"></font></p>
                        {/* <a href="about.html" class="btn btn-style mt-5">Discover More</a> */}
                </div>
            </div>
        </div>
    </section>
    </div>
    {/* <section class="w3l-content-11-main py-5">
        <div class="container py-lg-5 py-md-4 py-2">
            <div class="title-main text-center mx-auto mb-md-5 mb-4" >
                <h5 class="sub-title">Meet Our Team</h5>
                <h3 class="title-style">Professional Staff</h3>
            </div>
            <div class="row justify-content-center">
                <div class="col-md-4 col-sm-6">
                    <div class="position-relative">
                        <img src="assets/images/testi1.jpg" class="img-fluid" alt="image"/>
                        <div class="text-position">
                            <h4><a href="about.html">Buggem</a></h4>
                            <br></br>
                            <div class="social-team">
                                <ul class="list-inline">
                                    <li><a href="#url"><i class="fab fa-facebook-f"></i></a></li>
                                    <li><a href="#url"><i class="fab fa-twitter"></i></a></li>
                                    <li><a href="#url"><i class="fab fa-linkedin-in"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6 mt-sm-0 mt-4">
                    <div class="position-relative">
                        <img src="assets/images/testi1.jpg" class="img-fluid" alt="image"/>
                        <div class="text-position">
                        <h4><a href="about.html">Jimic</a></h4>
                            <br></br>
                            <div class="social-team">
                                <ul class="list-inline">
                                    <li><a href="#url"><i class="fab fa-facebook-f"></i></a></li>
                                    <li><a href="#url"><i class="fab fa-twitter"></i></a></li>
                                    <li><a href="#url"><i class="fab fa-linkedin-in"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6 mt-md-0 mt-4">
                    <div class="position-relative">
                        <img src="assets/images/testi1.jpg" class="img-fluid" alt="image"/>
                        <div class="text-position">
                        <h4><a href="about.html">Nasium</a></h4>
                            <br></br>
                            <div class="social-team">
                                <ul class="list-inline">
                                    <li><a href="#url"><i class="fab fa-facebook-f"></i></a></li>
                                    <li><a href="#url"><i class="fab fa-twitter"></i></a></li>
                                    <li><a href="#url"><i class="fab fa-linkedin-in"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> */}

    <section class="w3l-clients pb-5" id="testimonials">
        <div class="container py-lg-5 py-md-4 pt-2">
            <div class="row w3-testimonial-grids align-items-center">
                <div class="col-lg-6 w3-testimonial-content-top">
                    <div class="title-main mb-4">
                        <h5 class="sub-title">Tesimonials</h5>
                        <h3 class="title-style">What our <font color="#4895EF">customer's think</font> 
</h3>
                    </div>
                    <div id="owl-demo1" class="owl-carousel owl-theme py-sm-2 mb-4">
                        <div class="item">
                            <div class="testimonial-content">
                                <div class="testimonial">
                                    <blockquote>
                                        <q>"We recently had our air conditioner serviced by Air Frost, and the experience was outstanding. From the moment we called for an appointment to the technician's arrival and the efficient repair, everything was top-notch. Our AC is now working better than ever, and we couldn't be happier with the service. Highly recommended!"</q>
                                    </blockquote>
                                    <div class="testi-des">
                                        <div class="test-img"><img src="assets/images/testi1.jpg" class="img-fluid"
                                                alt="client-img"/>
                                        </div>
                                        <div class="peopl align-self">
                                            <h3>John wilson</h3>
                                            <p class="indentity">Subtitle goes here</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="testimonial-content">
                                <div class="testimonial">
                                    <blockquote>
                                        <q>"I want to express my gratitude to Air Frost for their quick response to our emergency AC repair. It was a hot summer day, and our AC stopped working. Within hours, a technician was at our doorstep, and they had it up and running again in no time. The professionalism and expertise were impressive. Thank you for saving the day!"</q>
                                    </blockquote>
                                    <div class="testi-des">
                                        <div class="test-img"><img src="assets/images/testi1.jpg" class="img-fluid"
                                                alt="client-img"/>
                                        </div>
                                        <div class="peopl align-self">
                                            <h3>Julia sakura</h3>
                                            <p class="indentity">Subtitle goes here</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="testimonial-content">
                                <div class="testimonial">
                                    <blockquote>
                                        <q>"I've used Air Frost for both AC installations and regular maintenance, and they consistently exceed my expectations. The team is knowledgeable, courteous, and always arrives on time. They take the time to explain everything and answer my questions. I wouldn't trust anyone else with my HVAC needs."</q>
                                    </blockquote>
                                    <div class="testi-des">
                                        <div class="test-img"><img src="assets/images/testi1.jpg" class="img-fluid"
                                                alt="client-img"/>
                                        </div>
                                        <div class="peopl align-self">
                                            <h3>Roy Mmdson</h3>
                                            <p class="indentity">Subtitle goes here</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="testimonial-content">
                                <div class="testimonial">
                                    <blockquote>
                                        <q>"After struggling with high energy bills for months, we decided to upgrade our old AC system. Air Frost guided us through the process, helping us choose an energy-efficient unit. Since the installation, our energy bills have significantly decreased, and our home is more comfortable than ever. Thanks for the excellent service!"</q>
                                    </blockquote>
                                    <div class="testi-des">
                                        <div class="test-img"><img src="assets/images/testi1.jpg" class="img-fluid"
                                                alt="client-img"/>
                                        </div>
                                        <div class="peopl align-self">
                                            <h3>Mike Thyson</h3>
                                            <p class="indentity">Subtitle goes here</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 position-relative ps-lg-5 mt-lg-0 mt-5">
                    <img src="assets/images/cu.png" alt="" class="radius-image img-fluid"/>
                </div>
            </div>
        </div>
    </section>
<Footer></Footer>
</>

);

}

export default About;