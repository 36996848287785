import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
function Mcarousel() {
  return (
<>

<Carousel data-bs-theme="dark">
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="assets/images/bng.jpg"
          alt="First slide"
        />
        <Carousel.Caption>
          <h6 class="carof">Power Of Extreme Cool...</h6>
          {/* <p class="carop">Fast, friendly, and fully functional, our professional Air Conditioning Repair Service is well renowned throughout the Eastern province.</p> */}
        </Carousel.Caption>
      </Carousel.Item>
    
   
    </Carousel>
 



</>
  )
}

export default Mcarousel