


import Footer from '../Pages/Footer';
function Contact() {
    
    return (
        <>    
         <div class="snowflakes" aria-hidden="true">
 
 <div class="snowflake">
 <font color='#1e96fc'>❅</font>
 </div>
 <div class="snowflake">
 <font color='#1e96fc'>❅</font>
 </div>
 <div class="snowflake">
 <font color='#1e96fc'>❅</font>
 </div>
 <div class="snowflake">
 <font color='#1e96fc'>❅</font>
 </div>
 <div class="snowflake">
 <font color='#1e96fc'>❅</font>
 </div>
 <div class="snowflake">
 <font color='#1e96fc'>❅</font>
 </div>
 <div class="snowflake">
 <font color='#1e96fc'>❅</font>
 </div>
 <div class="snowflake">
 <font color='#1e96fc'>❅</font>
 </div>
 <div class="snowflake">
 <font color='#1e96fc'>❅</font>
 </div>
 <div class="snowflake">
 <font color='#1e96fc'>❅</font>
 </div>
</div>
<div id="carouselExampleSlidesOnly" class="carousel slide" data-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active">
      {/* <img class="d-block w-100 clsy" src="assets/images/c1.png" alt="First slide"/> */}
    </div></div></div>
    <section class="w3l-bottom-grids-6 py-5 w3l-w3l-contacts-12 py-5">
<div class="container pt-lg-5 pt-md-4 pt-2"><center>
<h3 class="title-style">We would love to help you....</h3>
                    <h5 class="sub-title">Reach Us At:</h5></center><br></br><br></br>
    <div class="row justify-content-center">
    <div class="col-md-10 contacts12-main">
            <div class="area-box1">
            <form action="https://sendmail.w3layouts.com/submitForm" method="post" class="main-input">
                            <div class="row top-inputs">
                                <div class="col-md-6">
                                    <input type="text" class="bg2" placeholder="Name" name="Name" id="w3lName" required=""/>
                                </div>
                                <div class="col-md-6">
                                    <input type="email" name="email" placeholder="Email" id="Sender" required=""/>
                                </div>
                            </div>
                            <input type="text" placeholder="Phone Number" name="w3lName" id="Name" required=""/>
                            <textarea placeholder="Message" name="w3lMessage" id="w3lMessage" required=""></textarea>
                            <br></br>
                            <div class="text-end">
                                <button type="submit" class="btn btn-style">Send Now</button>
                            </div>
                        </form>
            </div>
        </div>
        
      
       
    </div>
</div>
</section>
  
        
 
    <section class="w3l-about-2 py-5">
        <div class="container py-lg-5 py-md-4 py-2">
            <div class="row justify-content-between align-items-center pb-lg-5">
                <div class="col-lg-6 about-2-secs-right mb-lg-0 mb-5">
                <h3 class="title-style mb-4">Contact Info</h3>
                        <div class="contact">

                        <a href=".com">
                                <p class="contact-text-sub">General Manager : Fadil  </p>
                                <p class="contact-text-sub">+966555873301</p>
                            </a>
                            <a href=".com">
                                <p class="contact-text-sub">Technical Manager : Joby </p>
                                <p class="contact-text-sub">+966532648650</p>
                            </a>

                            <a href=".com">
                                
                            </a>
                            <a href=".com">
                            
                            </a>
                         
                            <p class="contact-text-sub">Air Frost Trading EST Dammam<br/>Muhammadiya Saudi Arabia</p>
                            {/* <div class="buttons-teams">
                                <a href="#team"><span class="fab fa-facebook-square" aria-hidden="true"></span></a>
                                <a href="#team"><span class="fab fa-twitter-square" aria-hidden="true"></span></a>
                                <a href="#team"><span class="fab fa-google-plus-square" aria-hidden="true"></span></a>
                            </div> */}
                        </div>
               

                   </div>
                <div class="col-lg-6 about-2-secs-left ps-lg-5 mt-lg-4 mt-5">
                <img src="assets/images/cont.jpg" alt="card-image" class="img-fluid"/>
               
                </div>
            </div>
        </div>
    </section>

       
<Footer></Footer>
</>

);

}

export default Contact;


