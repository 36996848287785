import React, { useState } from 'react';


function Panel(props) {
  

  return (

    <aside className="panel" style={{ backgroundColor: props.panelStyle.backgroundColor }}>
      <h2 className="panel-header"><font color="white">{props.data.header}</font></h2>
      <p className="panel-info"><font color="white">{props.data.body}</font></p>

    </aside>
  );
}

function Selectors(props) {
  return (
    <div className="selectors">
      {props.data.map(item => (
        <div
          key={item.id}
          className={`selector ${props.activeID === item.id ? 'active' : ''}`}
          onClick={() => {
            console.log('Clicked on item with ID:', item.id);
            props._handleClick(item.id);
          }}
        ></div>
      ))}
    </div>
  );
}

function MyCarousel({ data }) {
  const [activeID, setActiveID] = useState(0); // Initialize activeID to 0
  const [buttonHover, setButtonHover] = useState(false);
  const [buttonStyle, setButtonStyle] = useState({ color: '#ffffff' });

  const handleButtonClick = () => {
    if (!buttonHover) {
      setButtonHover(true);
      setButtonStyle({ color: data[activeID].colour });
    } else {
      setButtonHover(false);
      setButtonStyle({ color: '#ffffff' });
    }
  };

  const handleChangeActive = id => {
    setActiveID(id);
    setButtonStyle({ color: data[id].colour }); // Error occurs here
  };

  return (
    <section className="wrapper" style={{ backgroundImage: `url('${data[activeID].img}')` }}>
    <Selectors
  data={data}
  activeID={activeID}
  _handleClick={id => {
    if (id !== activeID) {
      handleChangeActive(id);
    }
  }}
/>
      <Panel
        data={data[activeID]}
        panelStyle={{ backgroundColor: data[activeID].colour }}
        buttonStyle={buttonStyle}
        _buttonColour={handleButtonClick}
      />
    </section>
  );
}


export default MyCarousel;
