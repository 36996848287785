import Footer from '../Pages/Footer';
import Mcarousel from './Mcarousel';
import MyCarousel from './MyCarousel';

function Home() {
    const data =  [{
        id: 0,
        header: 'Power of Extreme Cool... ',
        body: 'Airfrost is the one of the best Air Conditioning Services in Saudi Arabia',
        colour: '#1e96fc',
        img: 'assets/images/bnr4.jpg'
       
    }, {
        id: 1,
        header: 'Cools You Better...',
        body: '25 Years of Excellence in the Market ',
        colour: '#1e96fc',
        img: 'assets/images/bnr2.jpg'
    }];
    return (
        <>
<br></br>

<div className="app">
      {/* Conditionally render desktop or mobile carousel */}
      {window.innerWidth > 768 ? (
        < MyCarousel data={data} />
      ) : (
        < Mcarousel data={data} />
      )}
    </div>
<br></br><br></br><br></br>
    <div class="w3l-grids-block-5 pb-5 pt-md-2 pt-4 ">
        <div class="container pb-lg-5 pb-md-4 pb-2">
            <div class="title-main text-center mx-auto mb-md-5 mb-4" >
                {/* <h5 class="sub-title">What We Offer</h5> */}
                <h3 class="title-style"><b>24-Hour <font color="#1e96fc">Service And Maintenance</font></b> </h3>
                <br></br>
            </div>
            
            <div class="row text-center justify-content-center">
                <div class="col-lg-4 col-md-6">
                    <div class="">
                        <div class="grids5-info position-relative">
                            <img src="./assets/images/ac2.jpg" alt="" class="img-fluid" />
                        </div>
                        <div class="content-main-top">
                            <h4><a>Air Conditioning Services</a></h4><br></br>
                            <p class="text">When it comes to keeping your home or business comfortable year-round, a reliable air conditioning system is essential. At Air Frost, we are your trusted partner for a wide range of air conditioning services. Our team of experienced technicians is dedicated to providing top-notch solutions to meet all your cooling needs.
                            </p><br/><br/>
                            {/* <a class="btn btn-style mt-4" href="services.html">Read More</a> */}
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mt-md-0 mt-4">
                    <div class="">
                        <div class="grids5-info position-relative">
                            <img src="assets/images/ac.jpg" alt="" class="img-fluid" />
                        </div>
                        <div class="content-main-top">
                            <h4><a >Installation</a></h4><br></br>
                            <p class="text">Are you in need of a new air conditioning system? Our expert technicians specialize in the seamless installation of various AC units. Whether you're looking to install a central air system, ductless mini-split, or any other type of cooling system, we've got you covered. We'll ensure your new AC unit is installed correctly to optimize efficiency and performance.
                            </p><br></br>
                            {/* <a class="btn btn-style " href="services.html">Read More</a> */}
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mt-lg-0 mt-4">
                    <div class="">
                        <div class="grids5-info position-relative">
                            <img src="assets/images/ab6.jpg" alt="" class="img-fluid" />
                        </div>
                        <div class="content-main-top">
                            <h4><a >Maintenance</a></h4><br></br>
                            <p class="text">Regular maintenance is key to extending the lifespan of your air conditioning system and ensuring it operates at peak efficiency. Our maintenance services include thorough inspections, filter cleaning or replacement, refrigerant checks, and more. Schedule routine maintenance to prevent breakdowns and keep your energy bills in check.
                            </p><br/>
                            {/* <a class="btn btn-style mt-4" href="services.html">Read More</a> */}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row text-center justify-content-center">
                <div class="col-lg-4 col-md-6">
                    <div class="">
                        <div class="grids5-info position-relative">
                            <img src="assets/images/hund.jpg" alt="" class="img-fluid" />
                        </div>
                        <div class="content-main-top">
                            <h4><a >Air Quality Services</a></h4><br></br>
                            <p class="text">we are dedicated to providing top-notch air quality services that complement your air conditioning system. Indoor air quality plays a crucial role in maintaining a healthy and comfortable living or working environment. When it comes to your air conditioning system, ensuring optimal air quality is essential not only for your comfort but also for your overall well-being.     </p><br/><br/>
                            {/* <a class="btn btn-style mt-4" href="services.html">Read More</a> */}
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mt-md-0 mt-4">
                    <div class="">
                        <div class="grids5-info position-relative">
                            <img src="assets/images/truck.jpg" alt="" class="img-fluid" />
                        </div>
                        <div class="content-main-top">
                            <h4><a >Free Collection & Delivery</a></h4><br></br>
                            <p class="text">Are you in need of a new air conditioning system? Our expert technicians specialize in the seamless installation of various AC units. Whether you're looking to install a central air system, ductless mini-split, or any other type of cooling system, we've got you covered. We'll ensure your new AC unit is installed correctly to optimize efficiency and performance.
                            </p><br></br>
                            {/* <a class="btn btn-style " href="services.html">Read More</a> */}
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mt-lg-0 mt-4">
                    <div class="">
                        <div class="grids5-info position-relative">
                            <img src="assets/images/ab4.jpeg" alt="" class="img-fluid" />
                        </div>
                        <div class="content-main-top">
                            <h4><a >Duct Cleaning</a></h4><br></br>
                            <p class="text">we specialize in professional duct cleaning services that can make a significant difference in your indoor air quality and comfort. When it comes to maintaining a healthy and efficient air conditioning system, one often-overlooked aspect is the cleanliness of your ductwork.</p><br/>
                            {/* <a class="btn btn-style mt-4" href="services.html">Read More</a> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
<div class="toping"></div>
<div class="firstbg">
<section class="w3l-bottom-grids-6 py-5">
<div class="container pt-lg-5 pt-md-4 pt-2">
    <center><h2><font color="white"><b>We provide our customers with...</b></font></h2></center>
    <br></br><br></br>
    <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6">
            <div class="area-box3">
            {/* <i class="fa-solid fa-heart"></i> */}
                <h4><a class="title-head">Complete Customer Satisfaction</a></h4>
                <p class="text">We provide customer's satisfaction rate of 100% we strive to keep our work to the best quality possible we try to build customer relations to the fullest and help then in finding the best service they need.</p>
            </div>
        </div>
        <div class="col-lg-4 col-md-6 mt-md-0 mt-4">
            <div class="area-box3">
            {/* <i class="fa-solid fa-earth-americas"></i> */}
            <h4><a  class="title-head">Comprehensive system inspections</a></h4>
                <p class="text">We offer comprehensive system inspection services that go beyond a cursory check. Regular maintenance and inspections are the keys to ensuring your air conditioning system operates efficiently, reliably, and extends its lifespan.</p>
     
             
            </div>
        </div>
        <div class="col-lg-4 col-md-6 mt-lg-0 mt-4">
            <div class="area-box3">
                {/* <i class="fas fa-thumbs-up"></i> */}
                <h4><a  class="title-head">Consultation and Design</a></h4>
                <p class="text">we offer expert consultation and custom design services tailored to your specific needs. When it comes to ensuring optimal comfort and energy efficiency in your indoor spaces, the consultation and design phase of your air conditioning system is paramount.</p>
               
            </div>
        </div>
    </div><br></br>
    <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6">
            <div class="area-box3">
            {/* <i class="fa-solid fa-chart-simple"></i> */}
            <h4><a  class="title-head">24/7 Assistance In Case Of Breakdown</a></h4>
                <p class="text">We are available 24/7 and even on national holidays we are ready to serve our customers with just a phone call.<br></br><br></br><br></br></p>
            </div>
        </div>
        <div class="col-lg-4 col-md-6 mt-md-0 mt-4">
            <div class="area-box3">
            {/* <i class="fa-solid fa-gears"></i> */}
            <h4><a  class="title-head">Environment-Friendly Practices</a></h4>
                <p class="text">we are providing environmentally responsible AC solutions. Our range of environment-friendly practices in air conditioning devices not only helps reduce carbon emissions but also ensures efficient cooling for our customers.<br></br><br></br></p>
            </div>
        </div>
        <div class="col-lg-4 col-md-6 mt-lg-0 mt-4">
            <div class="area-box3">
            {/* <i class="fa-solid fa-industry"></i> */}
                <h4><a  class="title-head">Energy-efficient system upgrades</a></h4>
                <p class="text">we specialize in energy-efficient system upgrades for your air conditioning system. In today's world, energy efficiency is not just a buzzword; it's a crucial consideration for both cost-conscious homeowners and environmentally conscious individuals.</p>
            </div>
        </div>
    </div>
</div>
</section></div>

<section class="w3l-about-2 py-5">
        <div class="container py-lg-5 py-md-4 py-2">
            <div class="row justify-content-between align-items-center pb-lg-5">
                <div class="col-lg-6 about-2-secs-right mb-lg-0 mb-5">
                    <div class="image-box inverse position-relative">
                        <div class="image-box__static">
                            <img src="assets/images/sedd.jpg" alt="" width="454" height="600"/>
                        </div>
                        <div class="image-box__float">
                       
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 about-2-secs-left ps-lg-5 mt-lg-4 mt-5">
                   
                    <h3 class="title-style"><font color="#1e96fc">Air Conditioning </font>Experts...</h3>
                    <p class="mt-4 text">We all love living in comfort, especially when the temperatures around us start to soar high. And living in a tropical nation means dealing with varied weather conditions. Depending on where you live in the country, the heat can at times get unbearable, much like the cold. And when it comes to heat, if the weather is too dry or too humid, it can leave you feeling more fatigued than you are. That’s when air conditioners come in handy. Even though it’s artificial cooling, they provide a much needed sigh of relief in the heat and ensure you feel calm and comfortable</p>
                    {/* <ul class="mt-4 list-style-lis">
                        <li><i class="fas fa-check-circle"></i>100% Customer Satisfaction</li>
                        <li><i class="fas fa-check-circle"></i>Free Collection & Delivery</li>
                        <li><i class="fas fa-check-circle"></i>Affordable Prices</li>
                    </ul> */}
                    {/* <a href="" class="btn btn-style mt-5">Discover More</a> */}
                </div>
            </div>
        </div>
    </section>

  
    <section class="home-block-3 py-xl-4 py-2">
        <div class="row m-0 align-items-center">
            <div class="col-lg-6 left-w3l-img p-0">
                <img src="assets/images/ab1.jpg" alt="card-image1" class="img-fluid"/>
            </div>
            <div class="col-lg-6 right-w3l-img mt-lg-0 mt-sm-4 mt-5">
                <div class="p-xl-5 p-sm-4 mx-xl-3">
                    
                    <h3 class="title-style">Book Our<font color="#1e96fc"> Air Conditioning Services..</font></h3>
                    <p class="sub-title mt-3 text">Ready to experience top-notch air conditioning services? Booking our services is quick and easy. We're here to keep you cool and comfortable all year round. Follow these simple steps to schedule an appointment:</p>
                    <div class="row mt-5 pt-xl-4">
                        <div class="col-sm-6 icon-style">
                        <i class="fa-solid fa-building-shield"></i>
                            <div class="ab-detail">
                                <h4 class="title-head mb-sm-3 mb-2"><a href="services.html">Safety Policy</a></h4>
                                <p class="text">At Air Frost, we are committed to providing a safe and healthy environment for our employees, customers, contractors, and visitors.Policy outlines our commitment to safety and the responsibilities of everyone involved in our operations.
                                </p>
                            </div>
                        </div>
                        <div class="col-sm-6 icon-style mt-sm-0 mt-4">
                        <i class="fa-solid fa-taxi"></i>
                            <div class="ab-detail">
                                <h4 class="title-head mb-sm-3 mb-2"><a href="services.html">Quality Services</a></h4>
                                <p class="text">We employ a team of skilled and experienced professionals who are experts in their respective fields. Our staff undergoes continuous training and development to stay current with industry trends and best practices
                                </p>
                            </div>
                        </div>
                    </div>
                    <br></br>
                    <center>
             <a href='/Contact'>    <button class="btn btn-info text-white">Get a Quote</button></a>   </center>
                </div>
            </div>
        </div>
    </section>


 





<Footer></Footer>




        </>

  );
 
}

export default Home;